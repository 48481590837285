@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /*
Author       : themesoft69
Template Name: Lariv - React Portfolio Template
Version      : 1.0.1
*/

  /* Google Fonts */
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");

  /* Reset Css */
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html,
  body {
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
    background: #252525;
    color: #fff;
    font-size: 15px;
  }

  p {
    line-height: 26px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
  }
}

@layer components {
  .boxShadow {
    box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  }
  .btn {
    @apply inline rounded-md border-2 border-theme bg-theme px-[26px] py-[12px] font-semibold text-white transition-all duration-300 hover:bg-btn;
  }
  .socialRound {
    @apply boxShadow  rounded-lg bg-btn p-[15px] text-[50px] text-text shadow-none  transition duration-300 hover:text-theme;
  }
  .pagination {
    @apply inline-flex h-10 w-10 items-center justify-center rounded-lg border border-theme transition duration-200 group-hover:border-transparent group-hover:bg-theme;
  }
  .inputBox {
    @apply h-[45px] w-full rounded-lg border bg-transparent pl-[10px]  text-text focus:outline-none dark:border-btn;
  }
  .navNotActive {
    @apply font-medium text-white transition-all duration-300 hover:text-theme;
  }
  .navActive {
    @apply font-medium text-theme;
  }
  .progress .break-all {
    background: #ff014f !important;
  }
  .gradient-text {
    font-size: 42px;
    background: -webkit-linear-gradient(#eee, #04091e);
    opacity: 0.1;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    top: 30px;
  }
  .bg-img {
    background-image: url("~/public/assets/images/show/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 0;
  }
  .bg-img_login {
    background-image: url("~/public/assets/images/show/bg-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 0;
  }
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.sidebar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.sidebar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.swiper {
  padding-bottom: 100px !important;
}
.swiper-pagination {
  bottom: 50px !important;
}
.swiper-pagination-bullet-active {
  background: #ff014f !important;
}

/* Loader Css */
.InitLoader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fingerprint-spinner,
.fingerprint-spinner * {
  box-sizing: border-box;
}

.fingerprint-spinner {
  height: 100px;
  width: 100px;
  padding: 2px;
  overflow: hidden;
  position: relative;
}

.fingerprint-spinner .spinner-ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #ff014f;
  animation: fingerprint-spinner-animation 1500ms
    cubic-bezier(0.68, -0.75, 0.265, 1.75) infinite forwards;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.fingerprint-spinner .spinner-ring:nth-child(1) {
  height: calc(90px / 9 + 0 * 90px / 9);
  width: calc(90px / 9 + 0 * 90px / 9);
  animation-delay: calc(50ms * 1);
}

.fingerprint-spinner .spinner-ring:nth-child(2) {
  height: calc(90px / 9 + 1 * 90px / 9);
  width: calc(90px / 9 + 1 * 90px / 9);
  animation-delay: calc(50ms * 2);
}

.fingerprint-spinner .spinner-ring:nth-child(3) {
  height: calc(90px / 9 + 2 * 90px / 9);
  width: calc(90px / 9 + 2 * 90px / 9);
  animation-delay: calc(50ms * 3);
}

.fingerprint-spinner .spinner-ring:nth-child(4) {
  height: calc(90px / 9 + 3 * 90px / 9);
  width: calc(90px / 9 + 3 * 90px / 9);
  animation-delay: calc(50ms * 4);
}

.fingerprint-spinner .spinner-ring:nth-child(5) {
  height: calc(90px / 9 + 4 * 90px / 9);
  width: calc(90px / 9 + 4 * 90px / 9);
  animation-delay: calc(50ms * 5);
}

.fingerprint-spinner .spinner-ring:nth-child(6) {
  height: calc(90px / 9 + 5 * 90px / 9);
  width: calc(90px / 9 + 5 * 90px / 9);
  animation-delay: calc(50ms * 6);
}

.fingerprint-spinner .spinner-ring:nth-child(7) {
  height: calc(90px / 9 + 6 * 90px / 9);
  width: calc(90px / 9 + 6 * 90px / 9);
  animation-delay: calc(50ms * 7);
}

.fingerprint-spinner .spinner-ring:nth-child(8) {
  height: calc(90px / 9 + 7 * 90px / 9);
  width: calc(90px / 9 + 7 * 90px / 9);
  animation-delay: calc(50ms * 8);
}

.fingerprint-spinner .spinner-ring:nth-child(9) {
  height: calc(90px / 9 + 8 * 90px / 9);
  width: calc(90px / 9 + 8 * 90px / 9);
  animation-delay: calc(50ms * 9);
}

@keyframes fingerprint-spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}

.slide-top-1 {
  -webkit-animation: slide-top 5s linear infinite alternate-reverse both;
  animation: slide-top 5s linear infinite alternate-reverse both;
}

.slide-top-2 {
  -webkit-animation: slide-top 8s linear infinite alternate-reverse both;
  animation: slide-top 8s linear infinite alternate-reverse both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

.pulse {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

.recharts-layer {
  color: #fff;
}

/* 00. ========= pluse animation ========== */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 2s linear;
  animation: wobble-hor-bottom 2s linear;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@media only screen and (max-width: 768px) {
  .swiper {
    padding-bottom: 60px !important;
  }
  .swiper-pagination {
    bottom: 20px !important;
  }
}
